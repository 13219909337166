@font-face {
  font-family: 'Regola Neue';
  src: url('/fonts/RegolaNeue-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Regola Neue';
  src: url('/fonts/RegolaNeue-RegularItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Regola Neue';
  src: url('/fonts/RegolaNeue-Semibold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Regola Neue';
  src: url('/fonts/RegolaNeue-SemiboldItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Regola Neue';
  src: url('/fonts/RegolaNeue-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Regola Neue';
  src: url('/fonts/RegolaNeue-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Regola Neue';
  src: url('/fonts/RegolaNeue-Semibold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Regola Neue';
  src: url('/fonts/RegolaNeue-SemiboldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
