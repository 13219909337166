:root {
  --color--background-black: theme('colors.background.black');
  --color--background-base: theme('colors.background.base');
  --color--background-card: theme('colors.background.card');
  --color--background-highlight: theme('colors.background.highlight');
  --color--background-overlay: theme('colors.background.overlay');
  --color--background-border: theme('colors.background.border');
  /* Chart*/
  --color-chart-pink: theme('colors.chart.pink');
  --color-chart-blue: theme('colors.chart.blue');
  /* New Gray */
  --color-newGray-100: theme('colors.newGray.100');
  --color-newGray-200: theme('colors.newGray.200');
  --color-newGray-400: theme('colors.newGray.400');
  --color-newGray-500: theme('colors.newGray.500');
  --color-newGray-600: theme('colors.newGray.600');
  --color-newGray-700: theme('colors.newGray.700');
  --color-newGray-800: theme('colors.newGray.800');
  --color-newGray-900: theme('colors.newGray.900');

  /*Primary Black */
  --color-primaryBlack-300: theme('colors.primaryBlack.300');
  --color-primaryBlack-400: theme('colors.primaryBlack.400');
  /*Primary Black */
  --color-primaryGray-400: theme('colors.primaryGray.400');
  --color-primaryGray-500: theme('colors.primaryGray.500');
  --color-primaryGray-600: theme('colors.primaryGray.600');
  /*Gray Ash */
  --color-grayAsh-100: theme('colors.grayAsh.100');
  --color-grayAsh-200: theme('colors.grayAsh.200');
  --color-grayAsh-300: theme('colors.grayAsh.300');
  --color-grayAsh-400: theme('colors.grayAsh.400');
  --color-grayAsh-500: theme('colors.grayAsh.500');
  --color-grayAsh-600: theme('colors.grayAsh.600');
  --color-grayAsh-700: theme('colors.grayAsh.700');
  --color-grayAsh-800: theme('colors.grayAsh.800');
  --color-grayAsh-900: theme('colors.grayAsh.900');
  /*Gray Cream */
  --color-grayCream-100: theme('colors.grayCream.100');
  --color-grayCream-200: theme('colors.grayCream.200');
  --color-grayCream-300: theme('colors.grayCream.300');
  --color-grayCream-400: theme('colors.grayCream.400');
  --color-grayCream-500: theme('colors.grayCream.500');
  --color-grayCream-600: theme('colors.grayCream.600');
  --color-grayCream-700: theme('colors.grayCream.700');
  --color-grayCream-800: theme('colors.grayCream.800');
  --color-grayCream-900: theme('colors.grayCream.900');
  /*Primary*/
  --color-primary-100: theme('colors.primary.100');
  --color-primary-200: theme('colors.primary.200');
  --color-primary-300: theme('colors.primary.300');
  --color-primary-400: theme('colors.primary.400');
  --color-primary-500: theme('colors.primary.500');
  --color-primary-600: theme('colors.primary.600');
  --color-primary-700: theme('colors.primary.700');
  --color-primary-800: theme('colors.primary.800');
  --color-primary-900: theme('colors.primary.900');
  /*Doger Blue*/
  --color-dodgerBlue-100: theme('colors.dodgerBlue.100');
  --color-dodgerBlue-200: theme('colors.dodgerBlue.200');
  --color-dodgerBlue-300: theme('colors.dodgerBlue.300');
  --color-dodgerBlue-400: theme('colors.dodgerBlue.400');
  --color-dodgerBlue-500: theme('colors.dodgerBlue.500');
  --color-dodgerBlue-600: theme('colors.dodgerBlue.600');
  --color-dodgerBlue-700: theme('colors.dodgerBlue.700');
  --color-dodgerBlue-800: theme('colors.dodgerBlue.800');
  --color-dodgerBlue-900: theme('colors.dodgerBlue.900');
  /*Bright Torquoise*/
  --color-brightTorquoise-100: theme('colors.brightTorquoise.100');
  --color-brightTorquoise-200: theme('colors.brightTorquoise.200');
  --color-brightTorquoise-300: theme('colors.brightTorquoise.300');
  --color-brightTorquoise-400: theme('colors.brightTorquoise.400');
  --color-brightTorquoise-500: theme('colors.brightTorquoise.500');
  --color-brightTorquoise-600: theme('colors.brightTorquoise.600');
  --color-brightTorquoise-700: theme('colors.brightTorquoise.700');
  --color-brightTorquoise-800: theme('colors.brightTorquoise.800');
  --color-brightTorquoise-900: theme('colors.brightTorquoise.900');
  /*Emerald*/
  --color-emerald-100: theme('colors.emerald.100');
  --color-emerald-200: theme('colors.emerald.200');
  --color-emerald-300: theme('colors.emerald.300');
  --color-emerald-400: theme('colors.emerald.400');
  --color-emerald-500: theme('colors.emerald.500');
  --color-emerald-600: theme('colors.emerald.600');
  --color-emerald-700: theme('colors.emerald.700');
  --color-emerald-800: theme('colors.emerald.800');
  --color-emerald-900: theme('colors.emerald.900');
  /*amber*/
  --color-amber-100: theme('colors.amber.100');
  --color-amber-200: theme('colors.amber.200');
  --color-amber-300: theme('colors.amber.300');
  --color-amber-400: theme('colors.amber.400');
  --color-amber-500: theme('colors.amber.500');
  --color-amber-600: theme('colors.amber.600');
  --color-amber-700: theme('colors.amber.700');
  --color-amber-800: theme('colors.amber.800');
  --color-amber-900: theme('colors.amber.900');
  /*red*/
  --color-red-100: theme('colors.red.100');
  --color-red-200: theme('colors.red.200');
  --color-red-300: theme('colors.red.300');
  --color-red-400: theme('colors.red.400');
  --color-red-500: theme('colors.red.500');
  --color-red-600: theme('colors.red.600');
  --color-red-700: theme('colors.red.700');
  --color-red-800: theme('colors.red.800');
  --color-red-900: theme('colors.red.900');
  /*social */
  --color-social-mail: theme('colors.social.mail');
  --color-social-twitter: theme('colors.social.twitter');
  --color-social-whatsapp: theme('colors.social.whatsapp');
  --color-social-telegram: theme('colors.social.telegram');
  --color-social-linkedin: theme('colors.social.linkedin');
  --color-social-facebook: theme('colors.social.facebook');
  --color-social-reddit: theme('colors.social.reddit');
}
