@keyframes increase-pattern-opacity {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 0.2;
  }
}

@keyframes decrease-pattern-opacity {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 0.1;
  }
}
.animate-pattern-forwards {
  animation-name: increase-pattern-opacity;
  animation-duration: 0.15s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.animate-pattern-backwards {
  animation-name: decrease-pattern-opacity;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.loader-dots div {
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader-dots div:nth-child(1) {
  left: 8px;
  animation: loader-dots1 0.6s infinite;
}
.loader-dots div:nth-child(2) {
  left: 8px;
  animation: loader-dots2 0.6s infinite;
}
.loader-dots div:nth-child(3) {
  left: 32px;
  animation: loader-dots2 0.6s infinite;
}
.loader-dots div:nth-child(4) {
  left: 56px;
  animation: loader-dots3 0.6s infinite;
}
@keyframes loader-dots1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loader-dots3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loader-dots2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
